/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'variables.scss';

@font-face {
  font-family: 'Berthold Imago';
  src: url('../Berthold_Imago_Bold.ttf') format('truetype');
}

body {
	margin-bottom: 40px;
	.main {
		height: auto;
		min-height: calc(100vh - 171px);
	}
}

*:not(.material-icons){
    font-family: 'Roboto', sans-serif !important;
}

.main__title,
.main__subtitle {
	font-family: "Berthold Imago" !important;
}

.flex {
  display: flex;
}

.mat-form-field {
  &-flex {
    background: rgba(0,0,0,0.04);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $darkGray;
  }
  &-label {
	font-size: 14px !important;
	font-weight: 300 !important;
	padding-left: 5px;
  }
  &.mat-focused {
    .mat-form-field-flex {
      background: none;
    }
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: $blue;
  }
  .mat-form-field-ripple {
    background-color: $blue;
  }
}

.mat-checkbox-checked {
	.mat-checkbox-background {
		background-color: $blue !important;
	}
}

.footer {
  .mat-button-wrapper {
    color: #fff;
    text-transform: uppercase;
  }
}

.mat-raised-button:disabled {
  background-color: grey !important;
}

@media only screen and (max-height: 650px) and (orientation: portrait) {
	.mat-datepicker-popup {
		left: 10px !important;
		right: 0!important;
	}
}

.mat-card {
	color: red !important;
}

.backend-data-change-snackbar {
	width: 100vw;
	max-width: 100vw;
	display: flex;
	justify-content: center;
	padding: 6px 18px;

	.mat-simple-snackbar {
		align-items: center;

		.mat-simple-snackbar-action {
			margin: 0 10px 0 48px;

			button {
				text-transform: uppercase;
				padding: 0 10px;
				background: #ac1921;
				color: #FFFFFF;
				align-items: center;
				border-radius: 2px;
				font-weight: 700;
			}
		}
	}
}

